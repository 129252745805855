import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addPhrases,
  canEnterPhrases,
  getCategories,
} from "../../api/apiService";
import { CategoriesResponse } from "../../models/categories";
import { EnterPhrasesView } from "./EnterPhrasesView";
import { EnteredPhrases } from "../../models/enteredPhrases";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export const EnterPhrases: React.FC = () => {
  const { gameId, userId: userName } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const minLength = 2;
  const buttonLabel = t("enterSave");
  const header = t("enterHeader");
  const subHeader = t("enterSubHeader");
  const phrasesNotCorrect = t("badPhraseToast");
  const phrasePlaceholder = t("phrasePlaceholder");
  const [categories, setCategories] = useState<CategoriesResponse | undefined>(
    undefined,
  );
  const [enteredPhrases, setEnteredPhrases] = useState<EnteredPhrases[]>([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (!gameId || !userName) {
      return;
    }
    canEnterPhrases(gameId, userName)
      .then((response) => {
        if (!response) {
          // api returned false - phrases have already been entered
          // consider creating a separate component
          navigate("/error");
          return;
        }

        getCategories(gameId)
          .then((response) => {
            setCategories(response);
          })
          .catch((error) => {
            // error getting categories
            navigate("/error");
          });
      })
      .catch((error) => {
        // error getting game (the most probable error - url is not ok)
        navigate("/error");
      });
  }, []);

  useEffect(() => {
    if (!categories) {
      return;
    }

    const phrases: EnteredPhrases[] = categories.categories.map((category) => {
      return {
        phrases: Array.from(
          { length: categories.phrasesPerCategory },
          () => "",
        ),
        category: category.name,
      };
    });

    setEnteredPhrases(phrases);
  }, [categories]);

  useEffect(() => {
    const containsEmpty = enteredPhrases.some((obj) =>
      obj.phrases.some((phrase) => phrase.trim().length < minLength),
    );
    setSaveButtonDisabled(containsEmpty);
  }, [enteredPhrases]);

  const onPhrasesSaved = async (e: FormEvent) => {
    e.preventDefault();

    setSaveButtonDisabled(true);
    addPhrases({
      gameId: gameId,
      userName: userName,
      phrases: enteredPhrases.map((obj) => obj.phrases).flat(),
    })
      .then(() => {
        navigate("/success");
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 422) {
          toast.error(phrasesNotCorrect);
          setSaveButtonDisabled(false);
          return;
        }
        navigate("/error");
      });
  };

  const onPhraseChanged = (category: string, value: string, index: number) => {
    const elementIndex = enteredPhrases.findIndex(
      (x) => x.category === category,
    );
    if (elementIndex === -1) {
      return;
    }
    const element = enteredPhrases[elementIndex];
    const phrases = [
      ...element.phrases.slice(0, index),
      value,
      ...element.phrases.slice(index + 1),
    ];

    element.phrases = phrases;
    setEnteredPhrases([
      ...enteredPhrases.slice(0, elementIndex),
      element,
      ...enteredPhrases.slice(elementIndex + 1),
    ]);
  };

  return (
    <>
      <EnterPhrasesView
        header={header}
        subHeader={subHeader}
        userName={userName}
        phrasePlaceholder={phrasePlaceholder}
        enteredPhrases={enteredPhrases}
        categories={categories?.categories?.map((cat) => cat.name)}
        phrasesPerCategory={categories?.phrasesPerCategory}
        buttonLabel={buttonLabel}
        onPhrasesSaved={onPhrasesSaved}
        onPhraseChanged={onPhraseChanged}
        saveButtonDisabled={saveButtonDisabled}
      />
    </>
  );
};
