import React from "react";
import { SharePhraseLinksProps } from "./SharePhraseLinks.props";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { UrlOwner } from "../../models/urlOwner";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Facebook, LinkedIn, WhatsApp, X } from "@mui/icons-material";
import {
  MainStack,
  PrimaryButton,
  RoundedCard,
} from "../../styled-components/styledComponents";
import { PageInfo } from "../PageInfo/PageInfo";

export const SharePhraseLinksView: React.FC<SharePhraseLinksProps> = ({
  urlOwners,
  header,
  subHeader,
  shared,
  onShare,
  onConfirm,
  onCancel,
  bulkShare,
  onShowHints,
  confirmButtonLabel,
  bulkShareLabel,
  cancelButtonLabel,
  shareButtonText,
}) => {
  const renderHeader = () => (
    <RoundedCard sx={{ width: 300 }} variant="outlined">
      <CardActions sx={{ justifyContent: "center" }}>
        <Button size="small" onClick={bulkShare}>
          {bulkShareLabel}
        </Button>
      </CardActions>
    </RoundedCard>
  );

  const renderItem = ({ item, index }: { item: UrlOwner; index: number }) => (
    <ListItem key={index} sx={{ padding: 0, marginBottom: 1 }}>
      <RoundedCard
        variant="outlined"
        sx={{
          width: 300,
          backgroundColor: shared.at(index) ? "#d1e7dd" : undefined,
        }}
      >
        <CardContent>
          <Typography variant={"body1"}>{item.owner}</Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            sx={{ mr: 3 }}
            onClick={() => onShare(item.url, index)}
          >
            {shareButtonText}
          </Button>
          <FacebookShareButton url={item.url} title={"Facebook"}>
            <Facebook />
          </FacebookShareButton>
          <LinkedinShareButton url={item.url} title={"LinkedIn"}>
            <LinkedIn />
          </LinkedinShareButton>
          <WhatsappShareButton url={item.url} title={"Whatsapp"}>
            <WhatsApp />
          </WhatsappShareButton>
          <TwitterShareButton url={item.url} title={"X"}>
            <X />
          </TwitterShareButton>
        </CardActions>
      </RoundedCard>
    </ListItem>
  );

  return (
    <MainStack spacing={2} alignItems="center">
      <PageInfo
        title={header}
        subTitle={subHeader}
        onSubTitleClick={onShowHints}
      />
      <Box sx={{ flexGrow: 1 }}>
        {renderHeader()}
        <List>
          {urlOwners.map((owner, index) =>
            renderItem({
              item: owner,
              index,
            }),
          )}
        </List>
      </Box>
      <PrimaryButton variant="contained" onClick={onConfirm}>
        {confirmButtonLabel}
      </PrimaryButton>
      <PrimaryButton variant="contained" onClick={onCancel}>
        {cancelButtonLabel}
      </PrimaryButton>
    </MainStack>
  );
};
