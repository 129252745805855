export enum Routes {
  Welcome = "/Welcome",
  EnterPlayerNames = "/EnterPlayerNames",
  EnterCategories = "/EnterCategories",
  SharePhraseLinks = "/SharePhraseLinks",
  DrawnTeams = "/DrawnTeams",
  Game = "/Game",
  Settings = "/Settings",
  Ranking = "/Ranking",
  Rules = "/Rules",
  Credits = "/Credits",
  Contact = "/Contact",
  ModeSelection = "/ModeSelection",
  PhrasesNumber = "/PhrasesNumber",
  ThemeSelection = "/ThemeSelection",
  Error = "/Error",
  GameSolo = "/GameSolo",
  SoloResult = "/SoloResult",
}
