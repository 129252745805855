import { ModeSelectionProps } from "./ModeSelection.props";
import React from "react";
import { Button, Stack } from "@mui/material";
import {
  MainStack,
  PrimaryButton,
} from "../../styled-components/styledComponents";
import Lottie from "lottie-react";
import rocketAnimation from "../../assets/animations/rocket.json";
import writingAnimation from "../../assets/animations/writing.json";
import aiAnimation from "../../assets/animations/ai.json";
import {
  CLASSIC_MODE_BACKGROUND,
  QUICK_PLAY_BACKGROUND,
  SOLO_MODE_BACKGROUND,
} from "../../themes/colors";
import { PageInfo } from "../PageInfo/PageInfo";

export const ModeSelectionView: React.FC<ModeSelectionProps> = ({
  classicModeLabel,
  quickPlayWithFriendsModeLabel,
  playSoloLabel,
  cancelButtonLabel,
  header,
  subHeader,
  onCancel,
  onShowHints,
  onClassicModeSelected,
  onQuickPlayWithFriendsModeSelected,
  onPlaySoloSelected,
}) => {
  return (
    <MainStack spacing={2} alignItems={"center"}>
      <PageInfo
        title={header}
        subTitle={subHeader}
        onSubTitleClick={onShowHints}
      />
      <Button
        variant="contained"
        sx={{
          width: 250,
          marginTop: "10vh !important",
          backgroundColor: CLASSIC_MODE_BACKGROUND,
          borderRadius: "20px",
          border: "solid 1px #FFF",
          fontWeight: "bold",
        }}
        onClick={onClassicModeSelected}
      >
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {classicModeLabel}
          <Lottie style={{ width: "50px" }} animationData={writingAnimation} />
        </Stack>
      </Button>
      <Button
        variant="contained"
        sx={{
          width: 250,
          backgroundColor: QUICK_PLAY_BACKGROUND,
          borderRadius: "20px",
          border: "solid 1px #FFF",
          fontWeight: "bold",
        }}
        onClick={onQuickPlayWithFriendsModeSelected}
      >
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {quickPlayWithFriendsModeLabel}
          <Lottie style={{ width: "50px" }} animationData={rocketAnimation} />
        </Stack>
      </Button>
      <Button
        variant="contained"
        sx={{
          width: 250,
          backgroundColor: SOLO_MODE_BACKGROUND,
          borderRadius: "20px",
          border: "solid 1px #FFF",
          fontWeight: "bold",
        }}
        onClick={onPlaySoloSelected}
      >
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {playSoloLabel}
          <Lottie style={{ width: "50px" }} animationData={aiAnimation} />
        </Stack>
      </Button>
      <PrimaryButton
        variant="contained"
        style={{
          marginTop: 200,
        }}
        onClick={onCancel}
      >
        {cancelButtonLabel}
      </PrimaryButton>
    </MainStack>
  );
};
