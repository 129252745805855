import React, { useEffect, useState } from "react";
import { GameSettingsView } from "./GameSettingsView";
import { useTranslation } from "react-i18next";
import { initializeGame } from "../../api/apiService";
import { Routes } from "../../constans/routes";
import {
  DEFAULT_PHRASES_PER_CATEGORY,
  DEFAULT_ROUND_SECONDS,
  DEFAULT_SKIPS_PER_TEAM,
} from "../../constans/rules";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseModal } from "../BaseModal/BaseModal";
import { BaseModalProps } from "../BaseModal/BaseModal.props";

export const GameSettings: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const playerNames = location.state.playerNames;
  const categories = location.state.categories;
  const [isLoading, setIsLoading] = useState(false);
  const [phrasesPerCategory, setPhrasesPerCategory] = useState(
    DEFAULT_PHRASES_PER_CATEGORY,
  );
  const [roundSeconds, setRoundSeconds] = useState(DEFAULT_ROUND_SECONDS);
  const [skips, setSkips] = useState<number>(DEFAULT_SKIPS_PER_TEAM);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<BaseModalProps>({
    header: "",
    message: "",
    showHintIcon: false,
    okAction: {
      action: () => setIsModalVisible(false),
      label: t("defaultOkBtn"),
    },
  });

  const header = t("settingsHeader");
  const subHeader = t("hintsBtn");
  const confirmButtonLabel = t("nextBtn");
  const cancelButtonLabel = t("previousBtn");
  const skipsLabel = t("skipsLabel");
  const roundSecondsLabel = t("roundSecondsLabel");
  const phrasesPerCategoryLabel = t("phrasesPerCategory");

  useEffect(() => {
    if (!playerNames || !categories) {
      navigate(Routes.Welcome);
      console.log("Invalid game parameters.");
    }
  }, [playerNames, categories, navigate]);

  const onConfirm = async () => {
    setIsLoading(true);
    const response = await initializeGame({
      names: playerNames.map((name: string) => name.trim()),
      categories: categories.map((cat: string) => cat.trim()),
      phrasesPerCategory: phrasesPerCategory,
    });

    if (response) {
      setIsLoading(false);
      console.log(response);
      navigate(Routes.SharePhraseLinks, {
        state: {
          playerNames: playerNames,
          urls: response.urls,
          gameId: response.gameId,
          skips: skips,
          roundSeconds: roundSeconds,
        },
      });
      return;
    }

    setIsLoading(false);
  };

  const onCancel = () => {
    navigate(Routes.EnterCategories, {
      state: {
        playerNames: playerNames,
        categories: categories,
      },
    });
  };

  const onShowHints = () => {
    setModalContent({
      header: t("hintsBtn"),
      message: t("settingsModal"),
      showHintIcon: true,
      okAction: {
        action: () => {
          setIsModalVisible(false);
        },
        label: t("okBtn"),
      },
    });
    setIsModalVisible(true);
  };

  const onSkipsChange = (value: number) => {
    setSkips(value);
  };

  const onRoundSecondsChange = (value: number) => {
    setRoundSeconds(value);
  };

  const onPhrasePerCategoryChange = (value: number) => {
    setPhrasesPerCategory(value);
  };

  return (
    <>
      <GameSettingsView
        header={header}
        subHeader={subHeader}
        confirmButtonLabel={confirmButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        onShowHints={onShowHints}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onPhrasePerCategoryChange={onPhrasePerCategoryChange}
        onRoundSecondsChange={onRoundSecondsChange}
        onSkipsChange={onSkipsChange}
        roundTimeSecondsLabel={roundSecondsLabel}
        roundTimeSeconds={roundSeconds}
        phrasesPerCategory={phrasesPerCategory}
        phrasesPerCategoryLabel={phrasesPerCategoryLabel}
        skipsLabel={skipsLabel}
        skips={skips}
        disableConfirmButton={isLoading}
      />
      <BaseModal {...modalContent} isVisible={isModalVisible} />
    </>
  );
};
