import React from "react";
import { PhrasesNumberProps } from "./PhrasesNumber.props";
import { Link, Slider, Stack, Typography } from "@mui/material";
import {
  MainStack,
  PrimaryButton,
} from "../../styled-components/styledComponents";
import { PageInfo } from "../PageInfo/PageInfo";

export const PhrasesNumberView: React.FC<PhrasesNumberProps> = ({
  confirmButtonLabel,
  cancelButtonLabel,
  maxPhrasesNumber,
  minPhrasesNumber,
  phrasesNumber,
  phrasesNumberLabel,
  header,
  subHeader,
  onCancel,
  onConfirm,
  onChange,
  onShowHints,
}) => {
  return (
    <MainStack spacing={2} alignItems={"center"}>
      <PageInfo
        title={header}
        subTitle={subHeader}
        onSubTitleClick={onShowHints}
      />
      <Typography
        style={{
          marginBlock: 40,
        }}
        align={"center"}
        variant={"h6"}
      >
        {phrasesNumberLabel}
      </Typography>
      <Slider
        onChange={onChange}
        min={minPhrasesNumber}
        max={maxPhrasesNumber}
        step={1}
        style={{
          width: 250,
        }}
        valueLabelDisplay="on"
        value={phrasesNumber}
      />
      <PrimaryButton
        variant="contained"
        style={{
          marginTop: 120,
        }}
        onClick={onConfirm}
      >
        {confirmButtonLabel}
      </PrimaryButton>
      <PrimaryButton variant="contained" onClick={onCancel}>
        {cancelButtonLabel}
      </PrimaryButton>
    </MainStack>
  );
};
