import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ModeSelectionView } from "./ModeSelectionView";
import { Routes } from "../../constans/routes";
import { BaseModalProps } from "../BaseModal/BaseModal.props";
import { BaseModal } from "../BaseModal/BaseModal";
import { GameModes } from "../../models/gameMode";

export const ModeSelection: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classicModeLabel = t("classicMode");
  const quickPlayWithFriendsModeLabel = t("quickPlayWithFriendsMode");
  const playSoloLabel = t("playSoloMode");
  const cancelButtonLabel = t("previousBtn");
  const header = t("selectMode");
  const subHeader = t("hintsBtn");
  const modalHintHeader = t("modeHintHeader");
  const modalHintContent = t("modeHintContent");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<BaseModalProps>({
    header: "",
    message: "",
    showHintIcon: false,
    okAction: {
      action: () => setIsModalVisible(false),
      label: t("defaultOkBtn"),
    },
  });

  const onClassicModeSelected = () => {
    navigate(Routes.EnterPlayerNames, {
      state: {
        mode: GameModes.Classic,
      },
    });
  };

  const onQuickModeSelected = () => {
    navigate(Routes.EnterPlayerNames, {
      state: {
        mode: GameModes.QuickWithFriends,
      },
    });
  };

  const onPlaySoloSelected = () => {
    navigate(Routes.EnterCategories, {
      state: {
        mode: GameModes.QuickSolo,
      },
    });
  };

  const onCancel = () => {
    navigate(Routes.Welcome);
  };

  const onShowHints = () => {
    setModalContent({
      header: "",
      message: modalHintHeader + "\n\n" + modalHintContent,
      showHintIcon: true,
      okAction: {
        action: () => {
          setIsModalVisible(false);
        },
        label: t("okBtn"),
      },
    });
    setIsModalVisible(true);
  };

  return (
    <>
      <ModeSelectionView
        classicModeLabel={classicModeLabel}
        quickPlayWithFriendsModeLabel={quickPlayWithFriendsModeLabel}
        cancelButtonLabel={cancelButtonLabel}
        onClassicModeSelected={onClassicModeSelected}
        onQuickPlayWithFriendsModeSelected={onQuickModeSelected}
        playSoloLabel={playSoloLabel}
        onPlaySoloSelected={onPlaySoloSelected}
        onCancel={onCancel}
        onShowHints={onShowHints}
        header={header}
        subHeader={subHeader}
      />
      <BaseModal {...modalContent} isVisible={isModalVisible} />
    </>
  );
};
