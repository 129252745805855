import { RulesProps } from "./Rules.props";
import React from "react";
import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { Rule, RuleType } from "../../models/rule";
import oneWord from "../../assets/1word.png";
import describe from "../../assets/describe.png";
import show from "../../assets/show.png";
import {
  MainStack,
  PrimaryButton,
  RoundedCard,
} from "../../styled-components/styledComponents";

const RuleHeaderWithContentCard: React.FC<{ rule: Rule }> = ({ rule }) => (
  <RoundedCard variant="outlined" sx={{ width: "80vw" }}>
    <Box sx={{ p: 2 }}>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography gutterBottom variant="h5" component="div">
          {rule.header}
        </Typography>
      </Stack>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {rule.content}
      </Typography>
    </Box>
  </RoundedCard>
);

const RuleContentOnlyCard: React.FC<{ rule: Rule }> = ({ rule }) => (
  <RoundedCard
    variant="outlined"
    sx={{
      width: "80vw",
      backgroundColor: "transparent",
      border: "none",
    }}
  >
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ color: "text.primary" }}>
        {rule.content}
      </Typography>
    </Box>
  </RoundedCard>
);

const RoundDescriptionCard: React.FC<{ rule: Rule; ruleNames: string[] }> = ({
  rule,
  ruleNames,
}) => (
  <RoundedCard variant="outlined" sx={{ width: "80vw" }}>
    <Box sx={{ p: 2 }}>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography gutterBottom variant="h5" component="div">
          {rule.header}
        </Typography>
        {rule.roundNumber === 1 && (
          <img
            style={{
              height: "15svh",
            }}
            src={describe}
            alt="Round logo"
          />
        )}
        {rule.roundNumber === 2 && (
          <img
            style={{
              height: "15svh",
            }}
            src={oneWord}
            alt="Round logo"
          />
        )}
        {rule.roundNumber === 3 && (
          <img
            style={{
              height: "15svh",
            }}
            src={show}
            alt="Round logo"
          />
        )}
      </Stack>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {rule.content}
      </Typography>
    </Box>
    <Divider />
    <Box sx={{ p: 2 }}>
      <Stack direction="row" spacing={1}>
        <Chip
          color={rule.roundNumber === 1 ? "primary" : "default"}
          label={ruleNames[0]}
          size="small"
        />
        <Chip
          color={rule.roundNumber === 2 ? "secondary" : "default"}
          label={ruleNames[1]}
          size="small"
        />
        <Chip
          color={rule.roundNumber === 3 ? "success" : "default"}
          label={ruleNames[2]}
          size="small"
        />
      </Stack>
    </Box>
  </RoundedCard>
);

export const RulesView: React.FC<RulesProps> = ({
  steps,
  cancelButtonLabel,
  onCancel,
  ruleNames,
  header,
}) => {
  const renderRule = (rule: Rule) => {
    switch (rule.type) {
      case RuleType.RoundDescription:
        return <RoundDescriptionCard rule={rule} ruleNames={ruleNames} />;
      case RuleType.HeaderWithContent:
        return <RuleHeaderWithContentCard rule={rule} />;
      case RuleType.ContentOnly:
        return <RuleContentOnlyCard rule={rule} />;
    }
  };

  return (
    <MainStack spacing={2} alignItems={"center"} sx={{ mb: 6 }}>
      <Typography align={"center"} variant={"h5"}>
        {header}
      </Typography>
      <List
        sx={{
          marginBottom: 2,
        }}
      >
        {steps?.length > 0 &&
          steps.map((rule, index) => (
            <ListItem key={index}>{renderRule(rule)}</ListItem>
          ))}
      </List>

      <PrimaryButton
        variant="contained"
        style={{
          marginTop: 20,
        }}
        onClick={onCancel}
      >
        {cancelButtonLabel}
      </PrimaryButton>
    </MainStack>
  );
};
