import { GameProps } from "./Game.props";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { TeamColor, teamColors } from "../../models/teamColots";

import {
  DangerousRounded,
  DoneRounded,
  PauseRounded,
  RedoRounded,
} from "@mui/icons-material";
import { PresenterChangeModal } from "../PresenterChangeModal/PresenterChangeModal";
import { BaseModal } from "../BaseModal/BaseModal";
import { PauseModal } from "../PauseModal/PauseModal";
import { getCustomBackgroundColor } from "../../functions/getCustomBackgroundColor";

export const GameView: React.FC<GameProps> = ({
  currentPresenterLabel,
  pointsLabel,
  okBtnLabel,
  teams,
  presenter,
  presenterModalVisible,
  pauseModalVisible,
  openPauseModal,
  presenterModalOkCallback,
  wrongAnswerCallback,
  addPointsCallback,
  skipCallback,
  resumeModal,
  onGameQuit,
  isSkipPossible,
  isModeChangedVisible,
  roundCompleted,
  currentPhrase,
  currentModeLabel,
  isConfirmModalVisible,
  alertContent,
  points,
  maxSkips,
  skipsLeft,
  seconds,
  maxSeconds,
  currentMode,
}) => {
  const [teamColor, setTeamColor] = useState<TeamColor | undefined>();
  const [customBackgroundColor, setCustomBackgroundColor] = useState<
    string | undefined
  >();
  const progress = (seconds / maxSeconds) * 100;

  useEffect(() => {
    setTeamColor(teamColors[presenter.teamIndex]);
  }, [presenter.teamIndex]);

  useEffect(() => {
    const color = getCustomBackgroundColor(currentPhrase);
    if (color) {
      setCustomBackgroundColor(color);
    } else {
      setCustomBackgroundColor(undefined);
    }
  }, [currentPhrase]);

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "0px !important",
      }}
    >
      {/* Reimplement and design this UI */}
      <Stack sx={{ width: "100%" }}>
        <Card
          variant={"outlined"}
          sx={{
            background:
              customBackgroundColor ??
              `linear-gradient(45deg, ${teamColor?.background} 30%, ${teamColor?.border} 90%)`,
            borderColor: teamColor?.border,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignContent: "center" }}
            >
              <IconButton onClick={openPauseModal}>
                <PauseRounded />
              </IconButton>
              <Stack spacing={2} alignItems={"center"}>
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    value={progress}
                    size={60}
                    thickness={4}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      color="textPrimary"
                    >
                      {seconds}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2">{currentModeLabel}</Typography>
              </Stack>
              <Typography variant="body1">
                {pointsLabel + points[presenter.teamIndex]}
              </Typography>
            </Stack>
          </Box>
        </Card>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Box sx={{ p: 4, display: "flex", justifyContent: "center" }}>
            <Typography variant="h4" textAlign={"center"}>
              {currentPhrase}
            </Typography>
          </Box>
        </Box>
        <Stack alignItems={"center"}>
          <Typography variant="body1">{skipsLeft + "/" + maxSkips}</Typography>
          <Box sx={{ p: 1, width: "100%" }}>
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <Button
                color={"error"}
                disabled={roundCompleted}
                sx={{
                  p: 1,
                  m: 1,
                  borderRadius: "20px",
                  borderWidth: "2px",
                }}
                fullWidth={true}
                onClick={wrongAnswerCallback}
                variant="outlined"
              >
                <DangerousRounded />
              </Button>

              <Button
                onClick={skipCallback}
                sx={{
                  p: 1,
                  m: 1,
                  borderRadius: "20px",
                  borderWidth: "2px",
                }}
                fullWidth={true}
                disabled={roundCompleted || !isSkipPossible}
                variant="outlined"
                color="warning"
              >
                <RedoRounded />
              </Button>

              <Button
                color="success"
                sx={{
                  p: 1,
                  m: 1,
                  borderRadius: "20px",
                  borderWidth: "2px",
                }}
                fullWidth={true}
                disabled={roundCompleted}
                onClick={addPointsCallback}
                variant="outlined"
              >
                <DoneRounded />
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <PresenterChangeModal
        title={currentPresenterLabel}
        subTitle={currentModeLabel}
        currentMode={currentMode}
        content={presenter.presenterName + " (" + presenter.teamName + ")"}
        okButtonLabel={okBtnLabel}
        color={teamColor}
        okCallback={presenterModalOkCallback}
        isVisible={presenterModalVisible}
      />
      <PauseModal
        teams={teams}
        currentPresenterLabel={currentPresenterLabel}
        isConfirmModalVisible={isConfirmModalVisible}
        alertContent={alertContent}
        isVisible={pauseModalVisible}
        onResume={resumeModal}
        onQuit={onGameQuit}
        presenter={presenter}
        teamColor={teamColor}
      />
      <BaseModal isVisible={isModeChangedVisible} {...alertContent} />
    </Box>
  );
};
