import React from "react";
import { EnterCategoriesProps } from "./EnterCategories.props";
import { Button, List, ListItem } from "@mui/material";
import { CATEGORY_NAME_MAX_LENGTH } from "../../constans/rules";
import {
  MainStack,
  PrimaryButton,
  PrimaryInput,
} from "../../styled-components/styledComponents";
import { PageInfo } from "../PageInfo/PageInfo";

export const EnterCategoriesView: React.FC<EnterCategoriesProps> = ({
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  onCategoriesNumberIncreased,
  onCategoriesNumberDecreased,
  increaseCategoriesNumberDisabled,
  decreaseCategoriesNumberDisabled,
  header,
  subHeader,
  onCategoryChanged,
  categories,
  someCategoriesInvalid,
  categoryPlaceholderLabels,
  addBtnLabel,
  removeBtnLabel,
  onShowHints,
}) => {
  const renderInput = ({ item, index }: { item: string; index: number }) => (
    <ListItem key={index}>
      <PrimaryInput
        key={index}
        onChange={(text) => onCategoryChanged(text.target.value, index)}
        style={{
          width: 250,
        }}
        placeholder={categoryPlaceholderLabels[index]}
        slotProps={{
          htmlInput: {
            maxLength: CATEGORY_NAME_MAX_LENGTH,
          },
        }}
        value={item}
      />
    </ListItem>
  );

  return (
    <MainStack spacing={2} alignItems={"center"}>
      <PageInfo
        title={header}
        subTitle={subHeader}
        onSubTitleClick={onShowHints}
      />
      <List>
        {categories.map((item, index) =>
          renderInput({
            item,
            index,
          }),
        )}
        <ListItem>
          <Button
            onClick={onCategoriesNumberIncreased}
            disabled={increaseCategoriesNumberDisabled}
            style={{
              borderWidth: 2,
              width: 100,
              marginRight: 5,
              marginLeft: 25,
            }}
          >
            {addBtnLabel}
          </Button>
          <Button
            onClick={onCategoriesNumberDecreased}
            disabled={decreaseCategoriesNumberDisabled}
            style={{
              borderWidth: 2,
              width: 100,
              marginLeft: 5,
              marginRight: 25,
            }}
          >
            {removeBtnLabel}
          </Button>
        </ListItem>
      </List>
      <PrimaryButton
        variant="contained"
        disabled={someCategoriesInvalid}
        onClick={onConfirm}
      >
        {confirmButtonLabel}
      </PrimaryButton>
      <PrimaryButton variant="contained" onClick={onCancel}>
        {cancelButtonLabel}
      </PrimaryButton>
    </MainStack>
  );
};
