import { shuffleArray } from "./shuffleArray";
import { Team } from "../models/team";

export const drawTeamsOffline = (
  userNames: string[],
  teamsNumber: number,
): Team[] => {
  const shuffledNames = shuffleArray(userNames);

  const result: string[][] = Array.from({ length: teamsNumber }, () => []);

  shuffledNames.forEach((name: string, index: number) => {
    const arrayIndex = index % teamsNumber;
    result[arrayIndex].push(name);
  });

  const teams: Team[] = [];

  result.forEach((names: string[], index: number) => {
    teams.push({
      teamId: index.toString(),
      teamName: "",
      users: names,
    });
  });

  return teams;
};
