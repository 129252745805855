import { LoadingModalProps } from "./LoadingModal.props";
import React from "react";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";

export const LoadingModal: React.FC<LoadingModalProps> = ({ isLoading }) => {
  return (
    <Dialog
      open={isLoading ?? false}
      PaperProps={{
        style: {
          background: "transparent",
          border: "none",
          boxShadow: "none",
          padding: 1,
        },
      }}
    >
      <DialogContent>
        <CircularProgress size={60} thickness={3} />
      </DialogContent>
    </Dialog>
  );
};
