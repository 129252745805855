import React from "react";
import { SoloResultView } from "./SoloResultView";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Routes } from "../../constans/routes";

export const SoloResult: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const pointsLabel = t('soloRoundPointsLabel');
  const pointsText = t('soloRoundPointsText').replace('{Points}', location.state.points.toString());
  const buttonLabel = t("endGame");

  const onFinishGameClick = () => {
    navigate(Routes.Welcome);
  };

  return <SoloResultView pointsLabel={pointsLabel} pointsText={pointsText} onFinishGameClick={onFinishGameClick} buttonLabel={buttonLabel} />
}