import { useTranslation } from "react-i18next";
import { EnterPlayerNamesView } from "./EnterPlayerNamesView";
import React, { useEffect, useState } from "react";
import {
  LETTERS_DIGITS_ONLY_REGEX,
  MAX_PLAYERS,
  MIN_PLAYERS,
} from "../../constans/rules";
import { Routes } from "../../constans/routes";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseModalProps } from "../BaseModal/BaseModal.props";
import { BaseModal } from "../BaseModal/BaseModal";
import { GameModes } from "../../models/gameMode";

export const EnterPlayerNames: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const header = t("enterPlayerNamesHeader");
  const subHeader = t("hintsBtn");
  const confirmButtonLabel = t("nextBtn");
  const cancelButtonLabel = t("previousBtn");
  const playerPlaceholderLabel = t("playerPlaceholder");
  const addBtnLabel = t("addBtn");
  const removeBtnLabel = t("removeBtn");
  const [someNamesInvalid, setSomeNamesInvalid] = useState(false);
  const [playerNames, setPlayerNames] = useState<string[]>(
    Array.from({ length: MIN_PLAYERS }, () => ""),
  );
  const [increasePlayersNumberDisabled, setIncreasePlayersNumberDisabled] =
    useState(false);
  const [decreasePlayersNumberDisabled, setDecreasePlayersNumberDisabled] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<BaseModalProps>({
    header: "",
    showHintIcon: false,
    message: "",
    okAction: {
      action: () => setIsModalVisible(false),
      label: t("defaultOkBtn"),
    },
  });
  const mode = location.state.mode as GameModes;

  const onConfirm = async () => {
    if (playerNames) {
      const params = {
        state: {
          playerNames: playerNames.map((name) => name.trim()),
          mode: mode,
        },
      };

      navigate(Routes.EnterCategories, params);
    }
  };

  const onCancel = () => {
    navigate(Routes.ModeSelection);
  };

  const onNameChanged = (text: string, index: number) => {
    if (!LETTERS_DIGITS_ONLY_REGEX.test(text)) {
      return;
    }
    setPlayerNames([
      ...playerNames.slice(0, index),
      text,
      ...playerNames.slice(index + 1),
    ]);
  };

  const onPlayersNumberIncreased = () => {
    if (playerNames.length === MAX_PLAYERS) {
      return;
    }

    setPlayerNames([...playerNames, ""]);
  };

  const onPlayersNumbersDecreased = () => {
    if (playerNames.length === MIN_PLAYERS) {
      return;
    }

    setPlayerNames(playerNames.slice(0, -1));
  };

  const hasDuplicateNames = (playerNames: string[]) => {
    const lowercaseSet = new Set<string>();

    for (const str of playerNames) {
      const lowercaseStr = str.toLowerCase();

      if (lowercaseSet.has(lowercaseStr)) {
        // Duplicate found
        return true;
      }

      lowercaseSet.add(lowercaseStr);
    }

    // No duplicates found
    return false;
  };

  const onShowHints = () => {
    setModalContent({
      header: t("hintsBtn"),
      message: t("namesHint"),
      showHintIcon: true,
      okAction: {
        action: () => {
          setIsModalVisible(false);
        },
        label: t("okBtn"),
      },
    });
    setIsModalVisible(true);
  };

  useEffect(() => {
    const state = location.state as {
      playerNames: string[];
      playersNumber?: number;
    };
    if (state.playerNames) {
      setPlayerNames(state.playerNames);
    }
  }, [location.state]);

  useEffect(() => {
    setSomeNamesInvalid(
      playerNames.some((item) => item.length < 2) ||
        hasDuplicateNames(playerNames),
    );
  }, [playerNames]);

  useEffect(() => {
    setDecreasePlayersNumberDisabled(playerNames.length === MIN_PLAYERS);
    setIncreasePlayersNumberDisabled(playerNames.length === MAX_PLAYERS);
  }, [playerNames]);

  return (
    <>
      <EnterPlayerNamesView
        removeBtnLabel={removeBtnLabel}
        addBtnLabel={addBtnLabel}
        playerPlaceholderLabel={playerPlaceholderLabel}
        onPlayersNumberIncreased={onPlayersNumberIncreased}
        onPlayersNumberDecreased={onPlayersNumbersDecreased}
        increasePlayersNumberButtonDisabled={increasePlayersNumberDisabled}
        decreasePlayersNumberButtonDisabled={decreasePlayersNumberDisabled}
        someNamesInvalid={someNamesInvalid}
        onNameChanged={onNameChanged}
        playerNames={playerNames}
        header={header}
        subHeader={subHeader}
        confirmButtonLabel={confirmButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onShowHints={onShowHints}
      />
      <BaseModal {...modalContent} isVisible={isModalVisible} />
    </>
  );
};
