import { GameSoloProps } from "./GameSolo.props";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { getCustomBackgroundColor } from "../../functions/getCustomBackgroundColor";
import { BLUE_TEAM, BLUE_TEAM_BORDER } from "../../themes/colors";
import { RedoRounded } from "@mui/icons-material";
import { PrimaryInput } from "../../styled-components/styledComponents";

export const GameSoloView: React.FC<GameSoloProps> = ({
  seconds,
  maxSeconds,
  currentClue,
  maxRoundMistakes,
  roundMistakesLeft,
  maxRoundClues,
  roundCluesLeft,
  pointsLabel,
  answerButtonLabel,
  cluesLeftText,
  mistakesLeftText,
  answerPlaceholder,
  typedPhrase,
  points,
  onGuess,
  onGetClue,
  onTypePhrase,
}) => {
  const [customBackgroundColor, setCustomBackgroundColor] = useState<
    string | undefined
  >();
  useEffect(() => {
    const color = getCustomBackgroundColor(currentClue);
    if (color) {
      setCustomBackgroundColor(color);
    } else {
      setCustomBackgroundColor(undefined);
    }
  }, [currentClue]);
  useEffect(() => {
    const color = getCustomBackgroundColor(currentClue);
    if (color) {
      setCustomBackgroundColor(color);
    } else {
      setCustomBackgroundColor(undefined);
    }
  }, [currentClue]);
  const progress = (seconds / maxSeconds) * 100;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "0px !important",
      }}
    >
      <Stack sx={{ width: "100%" }}>
        <Card
          variant={"outlined"}
          sx={{
            background:
              customBackgroundColor ??
              `linear-gradient(45deg, ${BLUE_TEAM} 30%, ${BLUE_TEAM_BORDER} 90%)`,
            borderColor: BLUE_TEAM_BORDER,
          }}
        >
          <Box sx={{ p: 2 }}>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignContent: "center" }}
            >
              <Stack spacing={2} alignItems={"center"}>
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    value={progress}
                    size={60}
                    thickness={4}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      color="textPrimary"
                    >
                      {seconds}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body2">
                  {cluesLeftText
                    .replace("{Left}", roundCluesLeft.toString())
                    .replace("{Max}", maxRoundClues.toString())}
                </Typography>
                <Typography variant="body2">
                  {mistakesLeftText
                    .replace("{Left}", roundMistakesLeft.toString())
                    .replace("{Max}", maxRoundMistakes.toString())}
                </Typography>
              </Stack>
              <Typography variant="h5">{pointsLabel + points}</Typography>
            </Stack>
          </Box>
        </Card>
        <Box sx={{ mt: 2, marginBottom: "1vh" }}>
          <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <Typography variant="h4" textAlign={"center"}>
              {currentClue}
            </Typography>
          </Box>
        </Box>
        <Stack alignItems={"center"}>
          <Box
            sx={{
              p: 1,
              marginTop: "1vh",
              width: "100%",
              justifyItems: "center",
            }}
          >
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignContent: "center",
                width: "100%",
                maxWidth: "1000px",
              }}
            >
              <Stack sx={{ m: 1, width: "80%" }} spacing={2} direction="row">
                <PrimaryInput
                  placeholder={answerPlaceholder}
                  sx={{ width: "70%" }}
                  value={typedPhrase}
                  onChange={(e) => onTypePhrase(e.target.value)}
                />
                <Button
                  color="success"
                  sx={{
                    p: 1,
                    m: 1,
                    width: "30%",
                    borderRadius: "20px",
                    borderWidth: "2px",
                  }}
                  disabled={typedPhrase.length === 0}
                  fullWidth={true}
                  onClick={() => onGuess()}
                  variant="outlined"
                >
                  {answerButtonLabel}
                </Button>
              </Stack>
              <Button
                onClick={() => onGetClue(null, null)}
                sx={{
                  p: 1,
                  m: 1,
                  width: "20%",
                  borderRadius: "20px",
                  borderWidth: "2px",
                }}
                fullWidth={true}
                disabled={roundCluesLeft === 0}
                variant="outlined"
                color="warning"
              >
                <RedoRounded />
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
