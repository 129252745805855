import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../constans/routes";
import { WelcomeView } from "./WelcomeView";
import React from "react";

export const Welcome: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = t("gameName");
  const letsPlayButtonLabel = t("start");
  const howToPlayButtonLabel = t("howToPlay");
  const authorsButtonLabel = t("authors");
  const onStartClick = () => {
    navigate(Routes.ModeSelection);
  };

  const onHowToPlayClick = () => {
    navigate(Routes.Rules);
  };
  const onCreditsClick = () => {
    navigate(Routes.Credits);
  };

  return (
    <WelcomeView
      title={title}
      onStartClick={onStartClick}
      onAuthorsClick={onCreditsClick}
      onHowToPlayClick={onHowToPlayClick}
      authorsButtonLabel={authorsButtonLabel}
      howToPlayButtonLabel={howToPlayButtonLabel}
      startButtonLabel={letsPlayButtonLabel}
    />
  );
};
