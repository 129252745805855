import { PresenterChangeModalProps } from "./PresenterChangeModal.props";
import React from "react";
import { Transition } from "../BaseModal/BaseModal";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { PrimaryButton } from "../../styled-components/styledComponents";
import oneWord from "../../assets/1word.png";
import describe from "../../assets/describe.png";
import show from "../../assets/show.png";
import { Rounds } from "../../constans/rules";

export const PresenterChangeModal: React.FC<PresenterChangeModalProps> = ({
  isVisible,
  title,
  subTitle,
  content,
  color,
  okButtonLabel,
  okCallback,
  currentMode,
}) => {
  return (
    <Dialog
      open={isVisible}
      fullScreen={true}
      maxWidth={"md"}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          background: `linear-gradient(45deg, ${color?.background} 30%, ${color?.border} 90%)`,
          borderColor: color?.border,
          padding: 6,
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
        }}
      >
        <Typography variant={"h6"}>{title}</Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
        }}
      >
        <Stack>
          <Box>
            {currentMode === Rounds.Describe && (
              <img
                style={{
                  height: "15svh",
                }}
                src={describe}
                alt="Round logo"
              />
            )}
            {currentMode === Rounds.OneWord && (
              <img
                style={{
                  height: "15svh",
                }}
                src={oneWord}
                alt="Round logo"
              />
            )}
            {currentMode === Rounds.Show && (
              <img
                style={{
                  height: "15svh",
                }}
                src={show}
                alt="Round logo"
              />
            )}
          </Box>

          <Stack
            alignContent={"center"}
            spacing={2}
            sx={{ textAlign: "center" }}
          >
            <Typography variant={"h4"}>{content}</Typography>
            <Typography variant={"h6"}>{subTitle}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
        <PrimaryButton
          variant={"contained"}
          onClick={okCallback}
          sx={{ minWidth: "200px" }}
        >
          {okButtonLabel}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
