import { Presenter } from "../models/presenter";
import { Team } from "../models/team";

export function switchPresenter(
  teams: Team[],
  currentPresenter: Presenter,
): Presenter {
  teams[currentPresenter.teamIndex].lastPresenterIndex =
    currentPresenter.presenterIndex;

  let newTeamIndex = currentPresenter.teamIndex + 1;
  if (newTeamIndex >= teams.length) {
    newTeamIndex = 0;
  }

  let newPresenterIndex =
    teams[newTeamIndex].lastPresenterIndex !== undefined
      ? teams[newTeamIndex].lastPresenterIndex! + 1
      : 0;

  if (newPresenterIndex >= teams[newTeamIndex].users.length) {
    newPresenterIndex = 0;
  }

  const newTeamName = teams[newTeamIndex].teamName;
  const newPresenterName = teams[newTeamIndex].users[newPresenterIndex];

  return {
    teamIndex: newTeamIndex,
    teamName: newTeamName,
    presenterIndex: newPresenterIndex,
    presenterName: newPresenterName,
  };
}
