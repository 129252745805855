import { PauseModalProps } from "./PauseModal.props";
import React from "react";
import { BaseModal, Transition } from "../BaseModal/BaseModal";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  MainDialog,
  PrimaryButton,
} from "../../styled-components/styledComponents";
import { PauseRounded } from "@mui/icons-material";

export const PauseModal: React.FC<PauseModalProps> = ({
  presenter,
  teams,
  currentPresenterLabel,
  alertContent,
  isConfirmModalVisible,
  isVisible,
  teamColor,
  onResume,
  onQuit,
}) => {
  const { t } = useTranslation();

  return (
    <MainDialog
      open={isVisible}
      TransitionComponent={Transition}
      maxWidth={"md"}
      fullWidth={true}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          display: "flex",
        }}
      >
        <PauseRounded fontSize={"large"} />
      </DialogTitle>
      <DialogContent>
        <Stack
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            color="#0D1B2A"
            textAlign={"center"}
            variant={"body1"}
            sx={{ marginBlock: 1 }}
          >
            {currentPresenterLabel}
          </Typography>
          <Typography
            color="#0D1B2A"
            textAlign={"center"}
            variant={"body1"}
            sx={{ marginBlock: 1 }}
          >
            {presenter.presenterName}
          </Typography>
          <Typography
            color="#0D1B2A"
            textAlign={"center"}
            variant={"body1"}
            sx={{ marginBlock: 2 }}
          >
            {t("teamPlaceholder") + presenter.teamName + ":"}
          </Typography>
          <List>
            {teams[presenter.teamIndex].users.map((player, index) => (
              <ListItem
                key={index}
                sx={{
                  padding: 0,
                }}
              >
                <Typography color="#0D1B2A" fontSize={"small"}>
                  {index + 1 + ". " + player}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <PrimaryButton
          sx={{ minWidth: "120px" }}
          variant={"contained"}
          onClick={onResume}
        >
          {t("return")}
        </PrimaryButton>
        <PrimaryButton
          sx={{ minWidth: "120px" }}
          variant={"contained"}
          onClick={onQuit}
        >
          {t("teamQuit")}
        </PrimaryButton>
      </DialogActions>
      <BaseModal
        {...alertContent}
        isVisible={isConfirmModalVisible}
        showHintIcon={false}
      />
    </MainDialog>
  );
};
