import React from "react";
import { MainStack, PrimaryButton } from "../../styled-components/styledComponents";
import { SoloResultProps } from "./SolorResult.props";
import { Box, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import confetti2 from "../../assets/animations/confetti_2.json";

export const SoloResultView: React.FC<SoloResultProps> = ({
  pointsLabel,
  pointsText,
  onFinishGameClick,
  buttonLabel
                                                          }) => {
  const [showAnimation, setShowAnimation] = React.useState(true);
  const onAnimationEnd = () => {
    setShowAnimation(false);
  };
  return <MainStack spacing={2} alignItems="center">
    <Stack sx={{ flexGrow: 1, textAlign: "center" }}>
      <Typography sx={{ mb: 2}} variant={"h5"} textAlign={"center"}>
        {pointsLabel}
      </Typography>
      <Typography sx={{ mb: 10 }} variant={"body1"}>
        {pointsText}
      </Typography>
      <PrimaryButton variant={"contained"} onClick={onFinishGameClick}>
        {buttonLabel}
      </PrimaryButton>
    </Stack>
    {showAnimation && (
      <Box position="absolute" top={0}>
        <Lottie
          animationData={confetti2}
          autoplay
          loop={false}
          onComplete={onAnimationEnd}
        />
      </Box>
    )}
  </MainStack>
}