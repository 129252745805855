import { Button, Card, Dialog, Stack, styled, TextField } from "@mui/material";
import {
  PRIMARY_INPUT_BACKGROUND,
  PRIMARY_INPUT_BORDER,
} from "../themes/colors";

// Primary button styled component
export const PrimaryButton = styled(Button)({
  background: "linear-gradient(45deg, #FF4C61 30%, #FF2A3E 90%)", // Gradient from red to darker red
  color: "#FFFFFF", // White text color
  borderRadius: "20px", // Rounded corners
  fontWeight: "bolder",
  padding: "10px",
  borderColor: "#FFF",
  border: "solid 1px #FFF",
  width: 200,
  "&:hover": {
    background: "linear-gradient(45deg, #FF2A3E 30%, #FF4C61 90%)", // Reversed gradient for hover state
  },
  "&:disabled": {
    background: "transparent", // Gradient for disabled state
    color: "rgba(255, 255, 255, 0.2)",
    borderColor: "rgba(255, 255, 255, 0.2)",
  },
});

// Secondary button styled component
export const SecondaryButton = styled(Button)({
  background: "linear-gradient(45deg, #4CAF50 30%, #388E3C 90%)", // Gradient from green to darker green
  color: "#FFFFFF", // White text color
  borderRadius: "20px", // Rounded corners
  fontWeight: "bold",
  padding: "10px",
  "&:hover": {
    background: "linear-gradient(45deg, #388E3C 30%, #4CAF50 90%)", // Reversed gradient for hover state
  },
  "&:disabled": {
    background: "linear-gradient(45deg, #A5D6A7 30%, #81C784 90%)", // Gradient for disabled state
    color: "#FFFFFF", // White text color
  },
});

export const RoundedCard = styled(Card)`
  border-radius: 1.5rem;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const PrimaryInput = styled(TextField)({
  background: "transparent", // Transparent background
  color: "#FFFFFF", // White text color
  borderRadius: "20px", // Rounded corners
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px", // Rounded corners for the input root
    "& fieldset": {
      borderColor: "#FFFFFF", // White border color
    },
  },
  "& .MuiInputBase-input": {
    color: "#FFFFFF", // White text color for input
  },
  "&.Mui-disabled .MuiInputBase-input": {
    color: "#FFFFFF", // White text color for disabled state
  },
});

export const MainStack = styled(Stack)`
  margin-top: 3rem;
`;

export const MainDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: "20px", // Rounded corners
    padding: "1rem",
    color: "#0D1B2A", // Font color
  },
  "& .MuiDialogTitle-root": {
    color: "#0D1B2A", // Font color for dialog title
  },
  "& .MuiDialogContent-root": {
    color: "#0D1B2A", // Font color for dialog content
  },
  "& .MuiDialogActions-root": {
    color: "#0D1B2A", // Font color for dialog actions
  },
});
