import { PageInfoProps } from "./PageInfo.props";
import React from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import idea from "../../assets/animations/idea.json";
import Lottie from "lottie-react";

export const PageInfo: React.FC<PageInfoProps> = ({
  title,
  subTitle,
  onSubTitleClick,
}) => {
  return (
    <Box>
      <Typography align={"center"} variant={"h5"}>
        {title}
      </Typography>
      <Stack direction="row" spacing={1} justifyContent="center">
        <Link
          underline="hover"
          sx={{
            cursor: "pointer",
          }}
        >
          <Typography
            align={"center"}
            variant={"h6"}
            color="primary"
            fontWeight="bold"
            marginTop={1}
            onClick={onSubTitleClick}
          >
            {subTitle}
          </Typography>
        </Link>
        <Lottie
          style={{
            width: "40px",
          }}
          animationData={idea}
          autoplay
          loop
        />
      </Stack>
    </Box>
  );
};
