import { RankingResultProps } from "./RankingResult.props";
import React, { useEffect } from "react";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import { PlayerRankingModel, TeamRankingModel } from "../../models/ranking";
import {
  MainStack,
  PrimaryButton,
  RoundedCard,
} from "../../styled-components/styledComponents";
import { WINNER_GOLD } from "../../themes/colors";
import winnerAnimation from "../../assets/animations/winner.json";
import confetti2 from "../../assets/animations/confetti_2.json";
import Lottie from "lottie-react";
import useScreenOrientation from "../../hooks/useScreenOrientation";

export const RankingResultView: React.FC<RankingResultProps> = ({
  ranking,
  ptsLabel,
  scoreboardLabel,
  endGameLabel,
  onEndGame,
}) => {
  const [showAnimation, setShowAnimation] = React.useState(true);
  const [isMobileDevice, setIsMobileDevice] = React.useState(false);
  const [isLandscape, setIsLandscape] = React.useState(false);
  const orientation = useScreenOrientation();

  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    setIsMobileDevice(isMobile);
  }, []);

  useEffect(() => {
    if (orientation.startsWith("landscape")) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  }, [orientation]);

  const onAnimationEnd = () => {
    setShowAnimation(false);
  };

  const renderAnimatedWinnerContent = (r: TeamRankingModel) => (
    <Box>
      {r.rank === 1 && (
        <Lottie style={{ width: "70px" }} animationData={winnerAnimation} />
      )}
      {r.rank !== 1 && <Box sx={{ width: "70px" }} />}
    </Box>
  );

  const renderTeamRankingItem = (r: TeamRankingModel, i: number) => (
    <ListItem key={"team_" + i}>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        {(!isMobileDevice || (isMobileDevice && isLandscape)) &&
          renderAnimatedWinnerContent(r)}
        <RoundedCard
          variant="outlined"
          sx={{
            padding: 2,
            borderColor: r.rank === 1 ? WINNER_GOLD : undefined,
            width: "30vw",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Typography variant={"body1"}>
            {r.rank + ". " + r.teamName + " - " + r.score + ptsLabel}
          </Typography>
        </RoundedCard>
      </Stack>
    </ListItem>
  );

  const renderPlayerRankingItem = (r: PlayerRankingModel, i: number) => (
    <ListItem key={"player_" + i}>
      <Typography variant={"body1"}>
        {r.rank + ". " + r.playerName + " - " + r.score + ptsLabel}
      </Typography>
    </ListItem>
  );

  return (
    <MainStack spacing={2} alignItems="center">
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant={"h5"} textAlign={"center"}>
          {scoreboardLabel}
        </Typography>
      </Box>
      <Stack spacing={2} direction="row" textAlign="center">
        <Stack>
          <List>
            {ranking.teamRanking.map((r, i) => renderTeamRankingItem(r, i))}
          </List>
        </Stack>
        <Stack>
          <List>
            {ranking.playerRanking.map((r, i) => renderPlayerRankingItem(r, i))}
          </List>
        </Stack>
      </Stack>
      <PrimaryButton variant={"contained"} onClick={onEndGame}>
        {endGameLabel}
      </PrimaryButton>
      {showAnimation && (
        <Box position="absolute" top={0}>
          <Lottie
            animationData={confetti2}
            autoplay
            loop={false}
            onComplete={onAnimationEnd}
          />
        </Box>
      )}
    </MainStack>
  );
};
