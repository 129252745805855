import { BLUE_TEAM, BLUE_TEAM_BORDER } from "../themes/colors";

const shrekPhrases = ["shrek", "fiona"];
const shrekColor: string = "#4CAF50";
const shrekColor2: string = "#26bd2c";
const shrekGradient: string = `linear-gradient(45deg, ${shrekColor} 30%, ${shrekColor2} 90%)`;

export const getCustomBackgroundColor = (currentPhrase: string) => {
  if (shrekPhrases.includes(currentPhrase)) {
    return shrekColor;
  }

  return undefined;
};
