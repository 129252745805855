import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#0D1B2A", // Dark blue background color
    },
    primary: {
      main: "#FF4C61", // Red color for primary elements
    },
    secondary: {
      main: "#FFB6C1", // Light pink color for secondary elements
    },
    warning: {
      main: "#e2e2e2", // Light pink color for secondary elements
    },
    text: {
      primary: "#FFFFFF", // White text color
      secondary: "#FFB6C1", // Light pink text color
    },
  },
  typography: {
    fontFamily: "Quicksand, Arial, sans-serif", // Set your preferred font family
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "#FFFFFF", // White color for headings
    },
    h2: {
      color: "#FFFFFF",
    },
    h3: {
      color: "#FFFFFF",
    },
    h4: {
      color: "#FFFFFF",
    },
    h5: {
      color: "#FFFFFF",
    },
    h6: {
      color: "#FFFFFF",
    },
    body1: {
      fontSize: "1rem",
      color: "#FFFFFF", // White color for body text
    },
    body2: {
      color: "#FFFFFF",
    },
    button: {
      textTransform: "none", // Disable uppercase transformation for buttons
    },
  },
});
export default theme;
