import React, { FormEventHandler } from "react";
import { EnteredPhrases } from "../../models/enteredPhrases";
import { Box, List, ListItem, Stack, Typography } from "@mui/material";
import {
  MainStack,
  PrimaryButton,
  PrimaryInput,
} from "../../styled-components/styledComponents";

export type EnterPhrasesProps = {
  userName?: string;
  categories?: string[];
  phrasesPerCategory?: number;
  buttonLabel: string;
  header: string;
  subHeader: string;
  saveButtonDisabled: boolean;
  enteredPhrases: EnteredPhrases[];
  phrasePlaceholder: string;
  onPhrasesSaved: FormEventHandler<HTMLFormElement>;
  onPhraseChanged: (category: string, phrase: string, index: number) => void;
};

export const EnterPhrasesView: React.FC<EnterPhrasesProps> = ({
  userName,
  categories,
  phrasesPerCategory,
  buttonLabel,
  header,
  subHeader,
  saveButtonDisabled,
  phrasePlaceholder,
  enteredPhrases,
  onPhrasesSaved,
  onPhraseChanged,
}) => {
  const renderInputs = () =>
    categories?.map((cat, index) => (
      <Box key={"category_" + index}>
        <Typography variant="h5" textAlign="center">
          {cat}
        </Typography>
        <List>
          {Array.from({ length: phrasesPerCategory ?? 0 }, () => "").map(
            (v, i) => (
              <ListItem
                key={"phrase_" + i + "_cat_" + index}
                sx={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <PrimaryInput
                  placeholder={phrasePlaceholder}
                  slotProps={{
                    htmlInput: {
                      minLength: 2,
                      maxLength: 40,
                    },
                  }}
                  sx={{
                    marginLeft: 0,
                    width: 300,
                  }}
                  value={
                    enteredPhrases.find((p) => p.category === cat)?.phrases[i]
                  }
                  onChange={(e) => {
                    onPhraseChanged(cat, e.target.value, i);
                  }}
                />
              </ListItem>
            ),
          )}
        </List>
      </Box>
    ));

  return (
    <MainStack spacing={2} alignItems="center">
      <Typography textAlign="center" variant="h4">
        {header.replace("{userName}", userName ?? "")}
      </Typography>
      <Typography textAlign="center" variant="body2">
        {subHeader}
      </Typography>
      <Box component="form" onSubmit={onPhrasesSaved}>
        {renderInputs()}
        <Stack
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <PrimaryButton
            variant="contained"
            disabled={saveButtonDisabled}
            type="submit"
          >
            {buttonLabel}
          </PrimaryButton>
        </Stack>
      </Box>
    </MainStack>
  );
};
