import React, { useEffect, useRef, useState } from "react";
import { GameSoloView } from "./GameSoloView";
import {
  DEFAULT_MAX_CLUES_SOLO,
  DEFAULT_MAX_MISTAKES_SOLO,
  DEFAULT_ROUND_SECONDS_SOLO,
} from "../../constans/rules";
import { LoadingModal } from "../LoadingModal/LoadingModal";
import incorrectAnimation from "../../assets/animations/incorrect.json";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseModalProps } from "../BaseModal/BaseModal.props";
import { BaseModal } from "../BaseModal/BaseModal";
import Lottie from "lottie-react";
import {
  getClueFromChat,
  getRandomPhrasesFromChat,
  guessRandomPhraseFromChat,
} from "../../api/apiService";
import { Routes } from "../../constans/routes";

export const GameSolo: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const pointsLabel = t("points");
  const answerPlaceholder = t("yourGuess");
  const answerButtonLabel = t("guessButtonLabel");
  const cluesLeftText = t("hintsLeftText");
  const mistakesLeftText = t("mistakesLeftText");
  const location = useLocation();
  const phrasesCount = location.state.phrasesNumber;
  const maxRoundSeconds = DEFAULT_ROUND_SECONDS_SOLO;
  const timerInterval = useRef<NodeJS.Timeout | undefined>(undefined);
  const [seconds, setSeconds] = useState<number>(maxRoundSeconds);
  const [isGameReady, setIsGameReady] = useState(false);
  const [phrases, setPhrases] = useState<string[]>([]);
  const [currentPhrase, setCurrentPhrase] = useState<string>("");
  const [currentClue, setCurrentClue] = useState("");
  const [viewedClues, setViewedClues] = useState<string[]>([]);
  const maxRoundMistakes = DEFAULT_MAX_MISTAKES_SOLO;
  const [roundMistakesLeft, setRoundMistakesLeft] =
    useState<number>(maxRoundMistakes);

  const maxRoundClues = DEFAULT_MAX_CLUES_SOLO;
  const [roundCluesLeft, setRoundCluesLeft] = useState<number>(maxRoundClues);
  const [points, setPoints] = useState<number>(0);
  const [typedPhrase, setTypedPhrase] = useState<string>("");
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState<number>(0);
  const [roundEndModalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<BaseModalProps>({
    header: "",
    message: "",
    showHintIcon: false,
    okAction: {
      action: () => setModalVisible(false),
      label: t("defaultOkBtn"),
    },
  });
  const [animation, setAnimation] = useState<any>();
  const [showAnimation, setShowAnimation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initializeGame().then((r) => {
      setIsGameReady(true);
    });
  }, []);

  useEffect(() => {
    if (!isGameReady) {
      return;
    }

    timerInterval.current = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(timerInterval.current);
      }
    }, 1000);

    return () => clearInterval(timerInterval.current);
  }, [isGameReady, maxRoundClues, maxRoundMistakes, seconds]);

  useEffect(() => {
    if (!viewedClues.includes(currentClue) && currentClue.length > 0) {
      setViewedClues([...viewedClues, currentClue]);
    }
  }, [currentClue, viewedClues]);

  const initializeGame = async () => {
    try {
      const getRandomPhrasesFromChatResponse = await getRandomPhrasesFromChat({
        language: i18n.language,
        count: phrasesCount,
        categories: location.state.categories,
      });

      // TODO: Development only
      // const getRandomPhrasesFromChatResponse: GetRandomPhrasesFromChatResponse =
      //   {
      //     phrases: ["shrek", "dupa", "fiona", "jan"],
      //   };

      setPhrases(getRandomPhrasesFromChatResponse.phrases);
      setCurrentPhrase(getRandomPhrasesFromChatResponse.phrases[0]);

      const getClueFromChatResponse = await getClueFromChat({
        phrase: getRandomPhrasesFromChatResponse.phrases[0],
        language: i18n.language,
        previousClues: [],
      });

      // TODO: Development only
      // const getClueFromChatResponse: GetClueFromChatResponse = {
      //   clue: "JEstes tym co jesz hueheuheuhuehuehuehuehu dsadsad ",
      // };

      setCurrentClue(getClueFromChatResponse.clue);
    } catch (err) {
      console.error(err);
    }
  };

  const onGuess = async () => {
    setIsLoading(true);
    const isCorrectResponse = await guessRandomPhraseFromChat({
      givenPhrase: typedPhrase,
      phraseToGuess: currentPhrase,
    });
    setIsLoading(false);
    if (isCorrectResponse.isCorrect) {
      addPoints();
    } else {
      setAnimation(incorrectAnimation);
      setShowAnimation(true);
      onMistake();
    }
  };

  const onGetClue = async (
    viewedCluesArg: [] | null = null,
    phrase: string | null = null,
  ) => {
    if (roundCluesLeft === 0) {
      return;
    }
    setIsLoading(true);
    const clueResponse = await getClueFromChat({
      phrase: phrase === null ? currentPhrase : phrase,
      previousClues: viewedCluesArg === null ? viewedClues : viewedCluesArg,
      language: i18n.language,
    });
    setIsLoading(false);
    // get clue from api
    setCurrentClue(clueResponse.clue);
    setRoundCluesLeft(roundCluesLeft - 1);
  };

  const addPoints = () => {
    // seconds left + clues left * 10
    const pointsToAdd = seconds + roundCluesLeft * 10;
    const newPoints = points + pointsToAdd;
    setPoints(newPoints);
    setModalContent({
      header: t("soloRoundSuccess"),
      message: t("soloRoundSuccessText") + currentPhrase,
      supportedOrientations: ["landscape"],
      showHintIcon: false,
      okAction: {
        action: () => {
          if (currentPhraseIndex + 1 < phrases.length) {
            goToNextRound();
            setModalVisible(false);
          } else {
            navigate(Routes.SoloResult, {
              state: {
                points: newPoints
              }
            });
          }
        },
        label:
          currentPhraseIndex + 1 < phrases.length
            ? t("soloRoundNext")
            : t("endGame"),
      },
    });
    setModalVisible(true);
  };

  const onMistake = () => {
    if (roundMistakesLeft === 0 || roundCluesLeft === 0) {
      setModalContent({
        header: t("soloRoundFail"),
        message: t("soloRoundFailText") + currentPhrase,
        supportedOrientations: ["landscape"],
        showHintIcon: false,
        okAction: {
          action: () => {
            if (currentPhraseIndex + 1 < phrases.length) {
              goToNextRound();
              setModalVisible(false);
            } else {
              navigate(Routes.SoloResult, {
                state: {
                  points: points
                }
              });
            }
          },
          label:
            currentPhraseIndex + 1 < phrases.length
              ? t("soloRoundNext")
              : t("endGame"),
        },
      });
      setModalVisible(true);
      return;
    }

    setRoundMistakesLeft(roundMistakesLeft - 1);
  };

  const goToNextRound = () => {
    setIsGameReady(false);
    const newPhraseIndex = currentPhraseIndex + 1;
    setCurrentPhraseIndex(newPhraseIndex);
    const newPhrase = phrases[newPhraseIndex];
    setCurrentPhrase(newPhrase);
    setTypedPhrase("");
    setViewedClues([]);
    onGetClue([], newPhrase).then(() => {
      setRoundCluesLeft(maxRoundClues);
      setRoundMistakesLeft(maxRoundMistakes);
      setIsGameReady(true);
      setSeconds(maxRoundSeconds);
    });
  };

  const onTypePhrase = (phrase: string) => {
    setTypedPhrase(phrase);
  };

  return (
    <>
      <GameSoloView
        currentClue={currentClue}
        maxSeconds={maxRoundSeconds}
        maxRoundMistakes={maxRoundMistakes}
        roundMistakesLeft={roundMistakesLeft}
        maxRoundClues={maxRoundClues}
        roundCluesLeft={roundCluesLeft}
        answerButtonLabel={answerButtonLabel}
        answerPlaceholder={answerPlaceholder}
        cluesLeftText={cluesLeftText}
        mistakesLeftText={mistakesLeftText}
        seconds={seconds}
        points={points}
        pointsLabel={pointsLabel}
        typedPhrase={typedPhrase}
        onGuess={onGuess}
        onGetClue={onGetClue}
        onTypePhrase={onTypePhrase}
      />
      {showAnimation && (
        <Lottie
          animationData={animation}
          style={{
            position: "absolute",
            top: "2rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          loop={false}
          onComplete={() => setShowAnimation(false)}
        />
      )}
      {!isGameReady && <LoadingModal isLoading={!isGameReady} />}
      {isLoading && <LoadingModal isLoading={isLoading} />}
      <BaseModal {...modalContent} isVisible={roundEndModalVisible} />
    </>
  );
};
