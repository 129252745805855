import { useTranslation } from "react-i18next";

export const Support: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="mainContainer">
      <h1>{t("contactUs")}</h1>
      <a href="mailto:garnekmobile@gmail.com">
        <h4>E-mail: garnekmobile@gmail.com</h4>
      </a>
    </div>
  );
};
