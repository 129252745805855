import { RankingResultView } from "./RankingResultView";
import { useLocation, useNavigate } from "react-router-dom";
import { Ranking } from "../../models/ranking";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { Routes } from "../../constans/routes";

export const RankingResult: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ptsLabel = t("pts");
  const scoreboardLabel = t("scoreboard");
  const endGameLabel = t("endGame");

  const ranking = location.state as Ranking;
  // TODO: DEV ONLY
  // const ranking: Ranking = {
  //   teamRanking: [
  //     { teamName: "Team A", score: 250, rank: 1 },
  //     { teamName: "Team B", score: 200, rank: 2 },
  //     { teamName: "Team C", score: 150, rank: 3 },
  //   ],
  //   playerRanking: [
  //     { playerName: "Player 1", score: 120, rank: 1 },
  //     { playerName: "Player 2", score: 100, rank: 2 },
  //     { playerName: "Player 3", score: 80, rank: 3 },
  //     { playerName: "Player 4", score: 80, rank: 4 },
  //     { playerName: "Player 4", score: 80, rank: 5 },
  //     { playerName: "Player 4", score: 80, rank: 6 },
  //     { playerName: "Player 4", score: 80, rank: 7 },
  //     { playerName: "Player 4", score: 80, rank: 8 },
  //     { playerName: "Player 4", score: 80, rank: 9 },
  //     { playerName: "Player 4", score: 80, rank: 10 },
  //     { playerName: "Player 4", score: 80, rank: 11 },
  //   ],
  // };

  useEffect(() => {
    if (!ranking) {
      navigate(Routes.Error);
    }
  }, [navigate, ranking]);

  useEffect(() => {
    // TODO: Add adds?
  }, []);

  const onEndGame = () => {
    navigate(Routes.Welcome);
  };

  return (
    <RankingResultView
      ptsLabel={ptsLabel}
      endGameLabel={endGameLabel}
      scoreboardLabel={scoreboardLabel}
      ranking={ranking}
      onEndGame={onEndGame}
    />
  );
};
