import axios, { AxiosResponse } from "axios";
import { CategoriesResponse } from "../models/categories";
import { PhrasesRequest } from "../models/phrasesRequest";
import apiConfiguration from "./api.config";
import { InitializeGameRequest } from "../models/initializeGameRequest";
import { InitializeGameResponse } from "../models/initializeGameResponse";
import { GetPhrasesResponse } from "../models/getPhrasesResponse";
import { DrawTeamsRequest } from "../models/drawTeamsRequest";
import { DrawTeamsResponse } from "../models/drawTeamsResponse";
import { GetRandomPhrasesFromChatResponse } from "../models/getRandomPhrasesFromChatResponse";
import { GetRandomPhrasesFromChatRequest } from "../models/getRandomPhrasesFromChatRequest";
import { GetClueFromChatRequest } from "../models/getClueFromChatRequest";
import { GetClueFromChatResponse } from "../models/getClueFromChatResponse";
import { GuessRandomPhraseFromChatRequest } from "../models/guessRandomPhraseFromChatRequest";
import { GuessRandomPhraseFromChatResponse } from "../models/guessRandomPhraseFromChatResponse";

const configuration = axios.create({
  //baseURL: "http://localhost:8080",
  baseURL: apiConfiguration.API_URL,
  timeout: 15000,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": apiConfiguration.API_KEY,
  },
  withCredentials: false,
});

export async function canEnterPhrases(
  gameId?: string,
  userName?: string,
): Promise<boolean> {
  const response: AxiosResponse<boolean> = await configuration.get(
    "/api/public/CanAddPhrases/" + gameId + "/" + userName,
  );
  return response.data;
}

export async function getCategories(
  gameId?: string,
): Promise<CategoriesResponse | undefined> {
  const response: AxiosResponse<CategoriesResponse> = await configuration.get(
    "/api/public/GetCategoriesByGameId/" + gameId,
  );
  return response.data;
}

export async function getPhrases(
  gameId?: string,
): Promise<GetPhrasesResponse | undefined> {
  const response: AxiosResponse<GetPhrasesResponse> = await configuration.get(
    "/api/mobile/GetPhrasesForGame/" + gameId,
  );
  return response.data;
}

export async function addPhrases(phrases: PhrasesRequest): Promise<any> {
  return await configuration.post(
    "/api/public/AddPhrases",
    JSON.stringify(phrases),
  );
}

export async function initializeGame(
  request: InitializeGameRequest,
): Promise<InitializeGameResponse> {
  try {
    const response = await configuration.post(
      "/api/mobile/InitializeGame",
      JSON.stringify(request),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllEntered(
  gameId: string,
): Promise<boolean | undefined> {
  try {
    const response = await configuration.get("/api/mobile/GameReady/" + gameId);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getRandomPhrasesFromChat(
  request: GetRandomPhrasesFromChatRequest,
): Promise<GetRandomPhrasesFromChatResponse> {
  try {
    const response = await configuration.post(
      "/api/public/GetRandomPhrasesFromChat",
      JSON.stringify(request),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getClueFromChat(
  request: GetClueFromChatRequest,
): Promise<GetClueFromChatResponse> {
  try {
    const response = await configuration.post(
      "/api/public/GetClueFromChat",
      JSON.stringify(request),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function guessRandomPhraseFromChat(
  request: GuessRandomPhraseFromChatRequest,
): Promise<GuessRandomPhraseFromChatResponse> {
  try {
    const response = await configuration.post(
      "/api/public/GuessRandomPhrase",
      JSON.stringify(request),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteGameData(
  gameId: string,
): Promise<boolean | undefined> {
  try {
    const response = await configuration.delete(
      "api/mobile/DeleteGame/" + gameId,
    );
    return response.status === 200;
  } catch (error) {
    throw error;
  }
}

export async function drawTeams(
  request: DrawTeamsRequest,
): Promise<DrawTeamsResponse | undefined> {
  try {
    const response = await configuration.post(
      "/api/mobile/DrawTeams",
      JSON.stringify(request),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
